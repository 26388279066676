.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 355px));
  grid-gap: 2rem;
  margin-top: 4rem;
  justify-content: center;
  margin-bottom: 150px;
}

@media (max-width: 600px) {
  .pricing-grid {
    margin-bottom: 60px;
  }
}
