footer {
  background-color: #ecf2fe;
  min-height: 325px;
}

footer .container {
  padding-top: 6rem;
  display: grid;
  grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr;
}

.footerheader {
  color: #000e3a;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.footerlinks a {
  width: max-content;
  color: #7b94e5 !important;
  display: flex;
  font-weight: 500;
  align-items: center;
  font-size: 16px;
  margin-top: 8px !important;
  position: relative;
}

.footerlinks a::after {
  position: absolute;
  content: "";
  left: 50%;
  bottom: -2px;
  transform: translate(-50%);
  height: 2px;
  background-color: #7b94e5;
  width: 0%;
  transition: all 0.5s ease;
}

.footerlinks a:hover:after {
  width: 100%;
}

a svg {
  margin-right: 5px;
}

.section1 .logo {
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
}

.newsletter {
  color: #7b94e5;
  font-size: 1rem;
  font-weight: 500;
}

footer .section1 span {
  margin-left: 1.5rem;
  color: #000e3a;
  font-weight: 700;
  white-space: nowrap;
}
