.info {
  background-color: #f3f7ff;
  border-radius: 10px;
  padding: 1.3rem 2rem;
  margin-bottom: 1.5rem;
}

.box {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  max-width: 352px;
  border: 2px solid #e2e7f1;
  border-radius: 14px;
  min-height: 495px;
  transition: all 0.5s ease;
}

.box:hover {
  transform: scale(1.15);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
}

.box img {
  width: 180px;
  margin: auto;
  margin-bottom: 2rem;
  padding-top: 1rem;
}

.text1 {
  color: #000e3a;
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.text2 {
  color: #6f84ad;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
}
