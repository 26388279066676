body {
  overflow-x: none;
}

.largeText {
  margin-top: 75px;
  margin-bottom: 75px;
  color: #6f84ad;
  font-size: 18px;
  font-weight: 400;
}
