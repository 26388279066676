.server-pricing-box {
  background-color: #ecf2fe;
  border-radius: 14px;
  padding: 20px;
}

.details {
  font-size: 18px;
  color: #000e3a;
  font-weight: 500;
  margin-bottom: 5px;
}

.feature-cost {
  font-size: 32px;
  font-weight: 700;
}

.feature-cost span {
  color: #6f84ad;
  font-size: 14px;
  font-weight: 500;
}

.border {
  border-radius: 10px;
  border: 2px solid #d8e1f4;
  padding: 22px;
  padding-bottom: 18px;
  margin-bottom: 20px;
}
