.logo {
  height: 45px;
  width: 45px;
  background-color: #000000;
  border-radius: 9px;
  padding: 6px;
}

.logo img {
  width: 33px;
}

.nav {
  height: 110px;
  width: 100%;
}

.links a {
  margin-right: 2rem;
  color: #7b94e5;
  font-weight: 500;
  font-size: 1rem;
}

.animateDown {
  animation: toDown 1.5s ease;
}

button.custom {
  height: 46px;
  width: 132px;
}

@keyframes toDown {
  from {
    transform: translate3d(0, -20px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0px, 0);
    opacity: 1;
  }
}

.nav span,
.footer span {
  color: #000e3a;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 1.7rem;
}

.bg {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-image: url("../assets/bg.png");
  background-size: auto 90vh;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  z-index: -1;
}

@media (max-width: 600px) {
  .landing .bg {
    background-size: 0;
  }
}

@media (max-width: 1100px) {
  .bg {
    background-size: auto 50vh;
  }
}
