@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.heartbeat {
  -webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
    transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
    transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
    transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.Toastify__close-button {
  display: none;
}

.Toastify__toast--default {
  background: #fff;
  color: #6f84ad;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-weight: 600;
  font-family: "Roboto", sans-serif;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

html,
body {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif !important;
}

a {
  text-decoration: none;
  outline: none;
}

.hero a {
  width: -webkit-max-content;
  width: max-content;
}

button {
  outline: none;
  border: none;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
}

.flex {
  display: flex;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.sp-bw {
  justify-content: space-between;
}

.jc-ce {
  justify-content: center;
}

.al-ce {
  align-items: center;
}

.container {
  max-width: 1130px;
  margin: auto;
}

.header {
  font-size: 2rem;
  color: #000e3a;
  margin-bottom: 1rem;
  font-weight: 700;
}

.main {
  padding-bottom: 50px;
}

/* Dropdown */

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  display: inline-block;
  margin-right: 12px;
  font-size: 12px;
  vertical-align: -1px;
  right: 30px !important;
  position: absolute;
  top: 0;
  -webkit-transform: translate(50%, 300%) scale(1.5);
          transform: translate(50%, 300%) scale(1.5);
}

.ant-collapse-item {
  margin-bottom: 1rem;
  border-radius: 8px;
  border: 2px solid #d0daef !important;
  background-color: #ecf2fe;
}

.ant-collapse-borderless > .ant-collapse-item:last-child,
.ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  border-radius: 8px !important;
}

.ant-collapse-header {
  font-family: "Roboto", sans-serif !important;
  font-size: 21px !important;
  font-weight: 700;
  padding: 28.5px 30px !important;
}

.anticon svg {
  -webkit-transform: scale3d(1.5) !important;
          transform: scale3d(1.5) !important;
}

.ant-collapse-content-box {
  padding-bottom: 30px !important;
  color: #6f84ad;
  font-size: 18px;
  font-weight: 500;
  padding-left: 30px !important;
  max-width: 84%;
}

.ant-collapse-item-active .ant-collapse-header {
  color: #19348d !important;
  padding-bottom: 16px !important;
}

/* Dropdown */

.coolBeans {
  color: #ff0;
  font-family: roboto;
  font-weight: 100;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  transition: 0.2s -webkit-transform ease-in-out;
  transition: 0.2s transform ease-in-out;
  transition: 0.2s transform ease-in-out, 0.2s -webkit-transform ease-in-out;
  will-change: transform;
  z-index: 0;
}
.coolBeans::after {
  background-color: #fff;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transform: translate(-100%, 0) rotate(10deg);
          transform: translate(-100%, 0) rotate(10deg);
  -webkit-transform-origin: top left;
          transform-origin: top left;
  transition: 0.2s -webkit-transform ease-out;
  transition: 0.2s transform ease-out;
  transition: 0.2s transform ease-out, 0.2s -webkit-transform ease-out;
  will-change: transform;
  z-index: -1;
}
.coolBeans:hover::after {
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
}
.coolBeans:hover {
  border: 2px solid transparent;
  color: #19348d !important;
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  will-change: transform;
}

.mobnav {
  height: 90px;
  padding: 0 1.5rem;
  display: none;
}

.hamburger-box {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.hamburger--slider .hamburger-inner {
  top: 2px;
}

.hamburger-inner,
.hamburger-inner:after,
.hamburger-inner:before {
  position: absolute;
  width: 33px;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  border-radius: 4px;
  background-color: #6f84ad;
}

.hamburger-inner:after {
  top: 20px;
}

.hamburger-inner {
  top: 50%;
  display: block;
  margin-top: -2px;
}

.hamburger--slider .hamburger-inner:before {
  top: 10px;
  transition-timing-function: ease;
  transition-duration: 0.15s;
  transition-property: opacity, -webkit-transform;
  transition-property: transform, opacity;
  transition-property: transform, opacity, -webkit-transform;
}

.hamburger-inner:before {
  top: -10px;
}

.hamburger-inner:after,
.hamburger-inner:before {
  display: block;
  content: "";
}

.hamburger-inner:before {
  top: -10px;
}

.hamburger {
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
}

.hamburger--slider.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
          transform: translate3d(0, 10px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner:before {
  -webkit-transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
          transform: rotate(-45deg) translate3d(-5.71429px, -6px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner:after {
  -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
          transform: translate3d(0, -20px, 0) rotate(-90deg);
}

.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner:after,
.hamburger.is-active .hamburger-inner:before {
  background-color: #000;
}

.mobnavlinks {
  overflow: hidden;
  min-height: 0px;
  opacity: 0;
  height: 0;
  position: absolute;
  background-color: #fff;
  width: 100%;
  z-index: 100;
  top: 90px;
  left: 0;
  display: flex;
  transition: opacity 0.5s ease;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navactive .mobnavlinks {
  min-height: calc(100vh - 90px) !important;
  height: auto !important;
  opacity: 1;
}

.navactive {
  transition: all 0.5s ease;
  background-color: #fff;
}

.mobnavlinks button {
  font-size: 20px !important;
  width: 160px !important;
  height: 54px !important;
}

.navactive .mobnavlinks {
  padding-bottom: 90px;
}

.mobnavlinks a {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #000 !important;
}

@media (max-width: 1180px) {
  .container {
    padding: 0 2rem;
  }

  .text {
    max-width: 100% !important;
  }

  .right-section {
    margin-bottom: 5rem;
  }

  .about {
    flex-wrap: wrap-reverse;
    justify-content: center !important;
  }
}

@media (max-width: 840px) {
  form {
    grid-template-columns: 1fr !important;
  }

  footer .container {
    grid-template-columns: 1.3fr 1fr 1fr !important;
    grid-gap: 3rem 2rem;
    padding-bottom: 4rem;
  }
}

@media (max-width: 747px) {
  .mobnav {
    display: flex !important;
  }

  footer .container {
    grid-gap: 2rem;
    grid-template-columns: 1fr !important;
  }

  .hero {
    min-height: calc(100vh - 90px) !important;
  }

  .primary-text {
    padding-bottom: 0.2em;
  }
  .primary-text {
    font-size: calc(39.10545px + 4.92955vw);
  }
  .primary-text {
    font-weight: 900 !important;
    text-align: center;
    line-height: 1.2 !important;
    padding-bottom: 1rem;
    margin-bottom: 0.5rem !important;
  }

  .sec-text {
    font-weight: 300;
    max-width: 15em;
    text-align: center !important;
    margin: 0 auto;
    padding-top: 0.5em;
  }

  .hero {
    justify-content: center !important;
    align-items: center;
  }

  .landing .hero {
    justify-content: flex-start !important;
  }

  .footerheader {
    font-size: 24px;
  }

  .sec-text {
    font-size: calc(20.51068px + 0.82557vw) !important;
  }
  .sec-text {
    line-height: 1.5 !important;
  }

  .container {
    padding: 1.5rem !important;
  }

  .main {
    padding-bottom: 0 !important;
  }

  .nav {
    display: none;
  }

  .twitter span {
    margin-left: 1rem;
  }

  .logoimg {
    bottom: -6rem !important;
    right: -1.3rem !important;
  }

  .logoimg svg {
    width: 140px !important;
  }

  .contactform {
    padding: 2rem 1.5rem !important;
  }

  .ant-collapse-header {
    font-size: 18px !important;
    padding: 16px !important;
    padding-right: 3rem !important;
  }

  .ant-collapse
    > .ant-collapse-item
    > .ant-collapse-header
    .ant-collapse-arrow {
    right: 16px !important;
    -webkit-transform: translate(50%, 275%) scale(1);
            transform: translate(50%, 275%) scale(1);
  }

  .ant-collapse-content-box {
    font-size: 17px !important;
    max-width: 95%;
    padding-left: 16px !important;
    padding-bottom: 16px !important;
  }

  .text {
    font-size: calc(20.51068px + 0.82557vw) !important;
    line-height: 1.5;
    font-weight: 400 !important;
  }

  .header {
    font-size: calc(30.79455px + 2.00712vw) !important;
    font-weight: 900 !important;
  }

  .footerlinks a,
  .newsletter {
    font-size: 20px !important;
  }

  .main,
  .about,
  .products,
  .faq,
  .contact {
    margin-bottom: 100px !important;
  }

  .text1 {
    font-size: 20px !important;
  }

  .text2 {
    font-size: calc(20.51068px + 0.82557vw);
    line-height: 1.5;
    font-weight: 400 !important;
  }

  input,
  textarea {
    font-size: 16px !important;
    padding-left: 20px !important;
  }

  ::-webkit-input-placeholder {
    font-size: 16px !important;
  }

  ::placeholder {
    font-size: 16px !important;
  }

  .faq {
    padding: 0 !important;
  }

  footer .container {
    padding: 4rem 2rem !important;
  }

  .box {
    -webkit-transform: scale(1) !important;
            transform: scale(1) !important;
  }

  .left-section {
    margin-right: 0.5rem !important;
  }
}

.blink {
  webkit-animation: 1s blinking step-end infinite;
  -webkit-animation: 1s blinking step-end infinite;
          animation: 1s blinking step-end infinite;
}

.blinking-cursor {
  font-weight: 100;
  margin-left: 2px;
  -webkit-transform: translateY(-1.5px);
          transform: translateY(-1.5px);
  -webkit-animation: 1s blink step-end infinite;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #fff;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    color: #fff;
  }
}

@keyframes blinking {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

@-webkit-keyframes blinking {
  from,
  to {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #dbe3f5 !important;
  border: none !important;
}

.ant-select-item {
  padding: 12px !important;
}

.ant-select {
  width: 100%;
  border-radius: 7px !important;
  overflow: hidden;
  color: #9ca8c3 !important;
  font-weight: 500;
  font-size: 18px !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: 53px !important;
  padding: 0 20px !important;
}

.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-search-input {
  height: 100% !important;
  line-height: 53px !important;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  line-height: 53px !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  background-color: #9ca8c3;
  font-family: "Roboto" !important;
  font-size: 16px;
  font-weight: 500 !important;
}

.ant-select-suffix {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

.loadscreen {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.loadcontent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.isloading {
  height: 100vh;
  margin: 0;
  padding: 0;
  width: 100vw;
  overflow: hidden;
}

.isloading img {
  width: 20px;
}

@media (max-width: 600px) {
  .isloading img {
    width: 15vw;
  }
}

.fade-in {
  -webkit-animation: fadein 1.5s ease-in;
          animation: fadein 1.5s ease-in;
}

@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (min-width: 1920px) {
  .nav.container {
    /*  max-width: 1300px !important; */
  }

  .bubble {
    left: 7.2% !important;
    top: 29.8% !important;
  }
}

.products {
  padding-top: 100px !important;
}

@media (width: 1920px) {
  .bg {
    background-image: url(/static/media/bigbg.5867a7b1.png) !important;
  }
}

@media (min-width: 2000px) {
  .links a,
  .nav span {
    font-size: 20px !important;
  }

  .bubble {
    left: 17.2% !important;
  }

  .bubble2 {
    left: 29.2% !important;
  }

  div.main.rellax > div.container.nav.flex.sp-bw.al-ce > div > button {
    font-size: 20px !important;
    height: 56px;
    width: 160px;
  }
}

.logo {
  height: 45px;
  width: 45px;
  background-color: #000000;
  border-radius: 9px;
  padding: 6px;
}

.logo img {
  width: 33px;
}

.nav {
  height: 110px;
  width: 100%;
}

.links a {
  margin-right: 2rem;
  color: #7b94e5;
  font-weight: 500;
  font-size: 1rem;
}

.animateDown {
  -webkit-animation: toDown 1.5s ease;
          animation: toDown 1.5s ease;
}

button.custom {
  height: 46px;
  width: 132px;
}

@-webkit-keyframes toDown {
  from {
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0px, 0);
            transform: translate3d(0, 0px, 0);
    opacity: 1;
  }
}

@keyframes toDown {
  from {
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0);
    opacity: 0;
  }

  to {
    -webkit-transform: translate3d(0, 0px, 0);
            transform: translate3d(0, 0px, 0);
    opacity: 1;
  }
}

.nav span,
.footer span {
  color: #000e3a;
  white-space: nowrap;
  font-size: 1rem;
  font-weight: 700;
  margin-left: 1.7rem;
}

.bg {
  height: 100%;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  background-image: url(/static/media/bg.7530c543.png);
  background-size: auto 90vh;
  background-repeat: no-repeat;
  background-position: 100% 0%;
  z-index: -1;
}

@media (max-width: 600px) {
  .landing .bg {
    background-size: 0;
  }
}

@media (max-width: 1100px) {
  .bg {
    background-size: auto 50vh;
  }
}

button {
  background-color: #19348d;
  border-radius: 7px;
  color: #fff !important;
  height: 53px;
  font-size: 18px !important;
  width: 100%;
  font-family: "Roboto" !important;
  font-weight: 500;
}

.no-stock {
  background-color: #dbe3f5 !important;
  color: #9ca8c3 !important;
  position: static !important;
  cursor: not-allowed;
}

.no-stock:hover {
  -webkit-transform: none !important;
          transform: none !important;
  color: #9ca8c3 !important;
}

.no-stock::after {
  background-color: #dbe3f5 !important;
}

.focus-in-expand {
  -webkit-animation: focus-in-expand 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: focus-in-expand 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.primary-text {
  font-size: 58px;
  font-weight: 700;
  max-width: 20ch;
  margin-bottom: 2rem;
  line-height: 1.3;
}

.sec-text {
  font-size: 21px;
  color: #6f84ad;
  margin-bottom: 2rem;
  max-width: 35ch;
  line-height: 1.36;
}

.hero {
  min-height: calc(100vh - 110px);
  padding-bottom: 5rem;
}

.plans {
  width: 150px;
}

.mob-only {
  display: none;
  margin-top: 3rem;
}

.mob-only img {
  width: 100%;
}

@media (max-width: 600px) {
  .mob-only {
    display: block;
  }
}

@-webkit-keyframes focus-in-expand {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes focus-in-expand {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.about {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 150px;
}

.text {
  color: #6f84ad;
  font-size: 21px;
  font-weight: 500;
  max-width: 46ch;
}

.cta {
  font-size: 14px;
  color: #fff;
  border-radius: 20px;
  min-height: 294px;
  width: 100%;
  max-width: 452px;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
  background-image: linear-gradient(to bottom, #373737, 5%, #222);
}

.tail {
  -webkit-animation: toAndFro 1.5s linear infinite;
          animation: toAndFro 1.5s linear infinite;
  -webkit-transform-origin: bottom center;
          transform-origin: bottom center;
}

@-webkit-keyframes toAndFro {
  0%,
  100% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  50% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
}

@keyframes toAndFro {
  0%,
  100% {
    -webkit-transform: rotate(2deg);
            transform: rotate(2deg);
  }
  50% {
    -webkit-transform: rotate(-2deg);
            transform: rotate(-2deg);
  }
}

.red {
  margin-right: 6px;
  background-color: #f2454f;
}

.yel {
  margin-right: 6px;
  background-color: #f4bf4f;
}

.gre {
  background-color: #61c554;
}

.circle {
  height: 13px;
  width: 13px;
  border-radius: 50%;
}

.circles {
  display: flex;
  padding: 14px 16px;
}

.top-section {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 1.3rem;
}

.center {
  text-align: center;
  color: #9a9a9a;
}

.bottom-section {
  padding-left: 1.5rem;
}

.pl {
  padding-left: 0.5rem;
}

.twitter span {
  color: #759ea0;
  font-size: 14px;
  margin-left: 0.5rem;
  font-weight: 400;
}

.twitter {
  margin-top: 0.7rem;
  padding-left: 10px;
}

.logoimg {
  position: absolute;
  bottom: -3rem;
  right: -0.3rem;
}

.left-section {
  position: relative;
  max-width: 460px;
  margin-right: 1.5rem;
  width: 100%;
}

.info {
  background-color: #f3f7ff;
  border-radius: 10px;
  padding: 1.3rem 2rem;
  margin-bottom: 1.5rem;
}

.box {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  max-width: 352px;
  border: 2px solid #e2e7f1;
  border-radius: 14px;
  min-height: 495px;
  transition: all 0.5s ease;
}

.box:hover {
  -webkit-transform: scale(1.15);
          transform: scale(1.15);
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.25);
}

.box img {
  width: 180px;
  margin: auto;
  margin-bottom: 2rem;
  padding-top: 1rem;
}

.text1 {
  color: #000e3a;
  font-size: 21px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.text2 {
  color: #6f84ad;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
}

.boxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 352px));
  justify-content: center;
  grid-gap: 2rem;
  margin-top: 2.5rem;
  align-items: center;
}

.products {
  margin-bottom: 150px;
}

.faq {
  background-color: #f8faff;
  padding: 150px 0;
  margin-bottom: 150px;
}

.faq-section {
  margin-top: 2rem;
}

.contact {
  margin-bottom: 150px;
}

.label {
  font-size: 14px;
  font-weight: 500;
  color: #6f84ad;
  margin-bottom: 0.5rem;
}

::-webkit-input-placeholder {
  font-size: 18px;
  font-weight: 500;
  color: #6f84ad;
}

::placeholder {
  font-size: 18px;
  font-weight: 500;
  color: #6f84ad;
}

input {
  background-color: #ecf2fe;
  border-radius: 7px;
  border: 1px solid #d0daef;
  outline: none;
  height: 53px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  color: #6f84ad;
  padding-left: 1.7rem;
  font-family: inherit;
  transition: all 0.3s ease;
}

form p {
  height: 1.3rem;
  margin: 2px 0;
  margin-bottom: 6px;
  font-family: inherit;
  text-align: center;
  color: rgb(250, 52, 52);
  font-weight: 600;
}

input:focus,
textarea:focus {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
}

textarea {
  background-color: #ecf2fe;
  border-radius: 7px;
  border: 1px solid #d0daef;
  resize: none;
  transition: all 0.3s ease;
  height: 345px;
  width: 100%;
  font-size: 18px;
  outline: none;
  padding-top: 16px;
  font-weight: 500;
  color: #6f84ad;
  padding-left: 2rem;
  font-family: inherit;
}

.contact a {
  color: #7b94e5;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  margin-top: 0.8rem;
  display: block;
}

form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.5rem;
}

.contactform {
  padding: 54px 74px;
  background-color: #f8faff;
  border-radius: 15px;
  margin-top: 3rem;
}

footer {
  background-color: #ecf2fe;
  min-height: 325px;
}

footer .container {
  padding-top: 6rem;
  display: grid;
  grid-template-columns: 1.3fr 1fr 1fr 1fr 1fr;
}

.footerheader {
  color: #000e3a;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.footerlinks a {
  width: -webkit-max-content;
  width: max-content;
  color: #7b94e5 !important;
  display: flex;
  font-weight: 500;
  align-items: center;
  font-size: 16px;
  margin-top: 8px !important;
  position: relative;
}

.footerlinks a::after {
  position: absolute;
  content: "";
  left: 50%;
  bottom: -2px;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
  height: 2px;
  background-color: #7b94e5;
  width: 0%;
  transition: all 0.5s ease;
}

.footerlinks a:hover:after {
  width: 100%;
}

a svg {
  margin-right: 5px;
}

.section1 .logo {
  margin-top: -0.5rem;
  margin-bottom: 1.5rem;
}

.newsletter {
  color: #7b94e5;
  font-size: 1rem;
  font-weight: 500;
}

footer .section1 span {
  margin-left: 1.5rem;
  color: #000e3a;
  font-weight: 700;
  white-space: nowrap;
}

.bubble {
  width: 16.25vh;
  height: 16.25vh;
  border-radius: 50%;
  background-color: #e8effc;
  -webkit-animation: scale 10s ease infinite;
          animation: scale 10s ease infinite;
  position: absolute;
  left: 5.8%;
  top: 26.8%;
  z-index: -1;
}

.bubble2 {
  width: 7vh;
  height: 7vh;
  border-radius: 50%;
  background-color: #e8effc;
  -webkit-animation: scale 10s ease infinite;
          animation: scale 10s ease infinite;
  position: absolute;
  left: 21.8%;
  top: 18%;
  z-index: -1;
}

.bubble3 {
  width: 3vh;
  height: 3vh;
  border-radius: 50%;
  background-color: #e8effc;
  -webkit-animation: scale 10s ease infinite;
          animation: scale 10s ease infinite;
  position: absolute;
  bottom: 25.8%;
  left: 32%;
  z-index: -1;
}

@-webkit-keyframes scale {
  0%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
}

@keyframes scale {
  0%,
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
}

.feature-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 46px;
  box-shadow: 0px 17px 46px rgba(20, 34, 80, 0.04);
  transition: all 0.5s ease;
}

.feature-box:hover {
  box-shadow: 0px 17px 46px rgba(20, 34, 80, 0.2);
  -webkit-transform: translateY(-10px);
          transform: translateY(-10px);
}

.featureheader {
  color: #000e3a;
  font-size: 21px;
  font-weight: 700;
  margin: 1rem 0;
}

.featuretexts {
  color: #6f84ad;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.36;
}

@media (max-width: 600px) {
  .feature-box img {
    width: 44px;
  }

  .featureheader {
    font-weight: 900;
    font-size: 26px;
    margin: 0.7rem 0;
  }

  .feature-box {
    padding: 36px;
  }

  .featuretexts {
    font-size: calc(20.51068px + 0.2557vw) !important;
  }
}

.featureBoxes {
  background-color: #f8faff;
  padding: 150px 0;
  padding-top: 90px;
}

@media (max-width: 600px) {
  .featureBoxes {
    padding: 70px 0;
  }
}

.grid {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 2rem;
}

.pack-details {
  font-size: 21px;
  font-weight: 500;
  color: #000e3a;
  display: grid;
  padding: 20px 28px;
  grid-template-columns: 1fr 1fr 2fr 2fr 1.3fr 75px;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
  cursor: pointer;
}

.pack-details div:last-child {
  cursor: pointer;
  transition: all 0.5s ease;
  justify-self: center;
}

.pack-container {
  margin-top: 1.2rem;
  border-radius: 8px;
  background-color: #f8faff;
  border: 2px solid #e2e7f1;
  overflow: hidden;
  transition: all 0.2s ease;
}

.pack-container:hover {
  border-color: #7b94e5;
}

.opened {
  border-color: #7b94e5;
}

.opened .pack-details > div:last-child {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.stock {
  border: 2px solid #f12e46;
  border-radius: 10px;
  padding: 6px 16px;
  height: 49px;
  width: -webkit-max-content;
  width: max-content;
  text-align: center;
}

.instock {
  border-color: #66e929;
}

.packs {
  transition: padding 0.5s ease;
  max-height: 0;
  height: initial;
  padding: 0 28px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(237px, 1fr));
  grid-gap: 16px;
}

.opened .packs {
  border-top: 2px solid #e2e7f1;
  padding: 28px;
  max-height: initial !important;
}

.server-pricing-box {
  background-color: #ecf2fe;
  border-radius: 14px;
  padding: 20px;
}

.details {
  font-size: 18px;
  color: #000e3a;
  font-weight: 500;
  margin-bottom: 5px;
}

.feature-cost {
  font-size: 32px;
  font-weight: 700;
}

.feature-cost span {
  color: #6f84ad;
  font-size: 14px;
  font-weight: 500;
}

.border {
  border-radius: 10px;
  border: 2px solid #d8e1f4;
  padding: 22px;
  padding-bottom: 18px;
  margin-bottom: 20px;
}

.server-headers {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2fr 1.3fr 75px;
  font-size: 21px;
  padding: 10px 28px;
  font-weight: 500;
  color: #7b94e5;
  border-bottom: 2px solid #e2e7f1;
}

.server-pricing {
  border: 2px solid #e2e7f1;
  padding: 30px;
  border-radius: 12px;
  margin-top: 4rem;
  margin-bottom: 150px;
}

.mob-only-pricing {
  display: none;
}

@media (max-width: 950px) {
  .server .server-pricing {
    display: none;
  }

  .mob-only-pricing {
    display: block;
  }
}

.text-focus-in {
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

@-webkit-keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes text-focus-in {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}

.front-page .primary-text {
  max-width: 18ch;
}

.front-page {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.imgcontainer {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 600px) {
  .imgcontainer {
    justify-content: center;
    margin-bottom: 3rem;
  }

  .mob-center {
    text-align: center;
  }

  .m-b {
    margin-bottom: 4rem;
  }
}

.imgcontainer img {
  width: 80%;
}

.dashimg img {
  width: 95%;
}

@media (max-width: 800px) {
  .front-page {
    padding: 0 3rem !important;
    grid-template-columns: 1fr 1fr;
  }
  .front-page .primary-text {
    font-size: 50px;
  }
}

@media (max-width: 600px) {
  .front-page {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
  }

  .front-page .primary-text {
    font-size: 30px !important;
  }

  .front-page .sec-text {
    font-size: 18px !important;
  }
}

@-webkit-keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(160px);
    transform: translateZ(160px);
  }
}
@keyframes slide-fwd-center {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  100% {
    -webkit-transform: translateZ(160px);
    transform: translateZ(160px);
  }
}

.slide-fwd-center {
  -webkit-animation: slide-fwd-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-fwd-center 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@media (max-width: 600px) {
  .imgcontainer img {
    max-height: 225px;
  }
}

.pricing-box {
  border-radius: 12px;
  overflow: hidden;
  background-color: #f8faff;
}

.dot {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: #6f84ad;
  margin-right: 10px;
}

.specs {
  padding: 28px 0;
  padding-bottom: 16px !important;
}

.spec {
  display: flex;
  align-items: center;
  font-size: 21px;
  color: #6f84ad;
  font-family: 600;
}

.spec:not(:last-child) {
  margin-bottom: 0.5rem;
}

.nostock .product {
  background-color: #dbe3f5 !important;
  color: #9ca8c3;
}

.nostock .num {
  color: #000e3a;
}

.product {
  background-color: #7b94e5;
  height: 118px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 30px 42px;
}

.num {
  font-size: 2rem;
  font-weight: 700;
  color: #fff;
  line-height: 32px;
}

.product {
  font-size: 18px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.5);
}

.padding-section {
  padding: 0 42px;
  padding-bottom: 2rem;
}

.price {
  font-size: 48px;
  font-weight: 700;
  margin-bottom: 1rem;
}

.currency {
  color: #7b94e5;
}

.price-num {
  color: #19348d;
  margin-right: 16px;
}

.type {
  color: #9ca8c3;
}

.select {
  margin-bottom: 0.5rem;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 355px));
  grid-gap: 2rem;
  margin-top: 4rem;
  justify-content: center;
  margin-bottom: 150px;
}

@media (max-width: 600px) {
  .pricing-grid {
    margin-bottom: 60px;
  }
}

.connectivity {
  position: relative;
  padding: 146px 0;
  background-image: url(/static/media/carrierbg.7b8d5917.png);
  background-size: cover;
}

@media (max-width: 600px) {
  .connectivity {
    padding: 0;
  }
}

.connectivity::after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.images1 img {
  width: 100%;
}

.images1 {
  margin-top: 4rem;
}

.connectivity .images {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: center;
  grid-gap: 16px;
}

.connectivity .images div {
  height: 90px;
  display: flex;
  justify-content: center;
}

.connectivity .images img {
  width: 80%;
}

.connectivity .mob-only {
  display: none;
}

@media (max-width: 600px) {
  .connectivity .mob-only {
    display: block;
  }

  .connectivity .mob-only .images {
    margin-top: 3rem;
  }

  .pc-only {
    display: none;
  }
}

body {
  overflow-x: none;
}

.largeText {
  margin-top: 75px;
  margin-bottom: 75px;
  color: #6f84ad;
  font-size: 18px;
  font-weight: 400;
}

