.focus-in-expand {
  -webkit-animation: focus-in-expand 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: focus-in-expand 0.8s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.primary-text {
  font-size: 58px;
  font-weight: 700;
  max-width: 20ch;
  margin-bottom: 2rem;
  line-height: 1.3;
}

.sec-text {
  font-size: 21px;
  color: #6f84ad;
  margin-bottom: 2rem;
  max-width: 35ch;
  line-height: 1.36;
}

.hero {
  min-height: calc(100vh - 110px);
  padding-bottom: 5rem;
}

.plans {
  width: 150px;
}

.mob-only {
  display: none;
  margin-top: 3rem;
}

.mob-only img {
  width: 100%;
}

@media (max-width: 600px) {
  .mob-only {
    display: block;
  }
}

@-webkit-keyframes focus-in-expand {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@keyframes focus-in-expand {
  0% {
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
